body {
  margin: 0;
  font-family: 'Roboto Slab', serif;
  font-size: 14pt;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


h2 {
  font-weight: 900;
  font-size: 32px;
  line-height: 1;
  margin: 0 0 4px;
  display: inline-block;
  vertical-align: top;
}

p {
  margin: 1em 0;
}

a {
  text-decoration: none;
  color: #1ea7fd;
}

ul {
  padding-left: 30px;
  margin: 1em 0;
}

li {
  margin-bottom: 8px;
}

.tip {
  display: inline-block;
  border-radius: 1em;
  font-size: 11px;
  line-height: 12px;
  font-weight: 700;
  background: #e7fdd8;
  color: #66bf3c;
  padding: 4px 12px;
  margin-right: 10px;
  vertical-align: top;
}

.tip-wrapper {
  font-size: 13px;
  line-height: 20px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.tip-wrapper svg {
  display: inline-block;
  height: 12px;
  width: 12px;
  margin-right: 4px;
  vertical-align: top;
  margin-top: 3px;
}

.tip-wrapper svg path {
  fill: #1ea7fd;
}



ul.tag-list {
  margin: 0px;
  padding: 0px;
}

.tag-list li {
  list-style-type: none;
  border: 1px solid rgba(0,0,0,.3);
  width: auto;
  float: left;
  margin: 2px;
  padding: 2px;
  font-size: 13px;
  font-style: italic;
  line-height: 1.0em;
  color: black;
  font-weight: 600;
  border-radius: 4px;
  background: #f0f0f0;
}


.wrapper {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

svg {
  display: inline-block;
  vertical-align: top;
}

.page-title {
  font-weight: bold;
  font-size: 1.6em;
  line-height: 0.55em;
  margin: 6px 0 6px 0px;
  display: inline-block;
  vertical-align: top;
}
a, a:active, a:visited {
  color: black;
}
h1 {
  font-weight: 900;
  font-size: 20px;
  line-height: 1;
  margin: 6px 0 6px 0px;
  display: inline-block;
  vertical-align: top;
}

h2 {
  font-size: 14px;
  line-height: 1;
  margin: 6px 0 6px 10px;
}
h3 {
  font-size: 12px;
  line-height: 1;
  margin: 6px 0 6px 10px;
}

button + button {
  margin-left: 10px;
}

section {
  font-size: 1.0rem;
  padding: 1.6em;
  padding-top: 1.0em;
  margin: 0 auto;
}
